<template>
  <PSection section-event id="academy-section-event" theme="white">
    <h2>이벤트</h2>
    <p class="sub-copy">공식 SNS를 팔로우하고, 다양한 이벤트 소식을 받아보세요!</p>
    <a class="btn-fb" href="https://www.facebook.com/LeagueofLegendsKor/" target="_blank" @click="toggleFb" /><a
    class="btn-in"
    href="https://instagram.com/leagueoflegendskorea"
    target="_blank"
    @click="toggleIg"
  />
    <ul class="step">
      <li @click="goInstagramEvent1">
        <h4>Event. 01</h4>
        <h3>협곡으로 소환</h3>
        <p>
          공식 SNS 채널의 이벤트 게시글에<br /> 댓글로 친구를 소환해보세요!
        </p>
      </li>
      <li @click="goInstagramEvent2">
        <h4>Event. 02</h4>
        <h3>전략적인 선택이군요 소환사님</h3>
        <p>
          SNS에 참가 완료 인증 사진과 정해진 해시태그를<br /> 사용해 업로드 해주세요!
        </p>
      </li>
      <li @click="goInstagramEvent3">
        <h4>Event. 03</h4>
        <h3>우승 대학교를 예측하라</h3>
        <p>
          공식 SNS 채널에서 LoL 1위 대학교를 차지할<br /> 우승 대학을 예측해보세요!
        </p>
      </li>
    </ul>
    <h5 class="event-copy">다양한 경품이 여러분을 기다리고 있습니다.</h5>
    <span>전투사관학교 피규어</span>
    <span>치킨 & 인게임 스킨</span>
    <span>전투사관학교 공식 상품</span>
    <article class="banner">
      <h2><em>지금 바로 대회에 참가하여</em>LoL 최고 대학의 명예를 <br class="hidden-tv-up" />얻어보세요!</h2>
      <router-link @click.native="onClickRouter" class="btn-go" to="/b/lol_academia"
      >바로 도전하기
      </router-link
      >
    </article>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';

export default {
  name: 'SectionEvent',
  mixins: [gtag],
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    goInstagramEvent1() {
      window.open('https://www.instagram.com/p/CPmox5rnQbd/?utm_source=ig_web_copy_link');
    },
    goInstagramEvent2() {
      window.open('https://www.instagram.com/p/CP4p987H-4h/?utm_medium=copy_link');
    },
    goInstagramEvent3() {
      window.open('https://www.instagram.com/p/CQU-w6SnCCq/?utm_medium=copy_link');
    },
    toggleFb() {
      this.trackEvent(`SNS_FaceBook`, 'click', `${this.upRouteGameId}`);
    },
    toggleIg() {
      this.trackEvent(`SNS_Instagram`, 'click', `${this.upRouteGameId}`);
    },
    onClickRouter() {
      this.trackEvent(`landing_challenge_02`, 'click', `${this.upRouteGameId}`);
      this.trackEvent('landing_Aleague_go2', 'click', 'riot');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[section-event] { .bgc(#1f2226); .tc; .p(48, 0, 0); .cover('@{academy}/asset/portal_BG_002.jpg'); .bg-xc;
  h2 {.fs(30); .ls(-2);}
  .sub-copy {.fs(16, 21px); .c(#5b91f5); .p(0, 77); word-break: keep-all; .mt(8); .mb(28); .font-g; .ls(-1);}
  > a {.ib; .wh(48); .br(12); .bgc(#5b91f5); .m(0, 20);
    &:nth-of-type(1) {.contain('@{academy}/icon/Icon_Social_Facebook.svg');}
    &:nth-of-type(2) {.contain('@{academy}/icon/Icon_Social_Instagram.svg');}
  }
  > ul { .mt(28);
    > li { .p(19, 25, 25); .w(335); .mh-c; .bgc(rgba(0, 0, 0, .3)); .mt(16);
      &:nth-of-type(1) {.mt(0); .pointer;}
      &:nth-of-type(2) h3 {.pt(27); .pb(11); .pointer;}
      &:nth-of-type(3) h3 {.pointer;}
      h3 {.fs(30); .bold; .pt(41); .pb(39); .medium;}
      h4 {.c(#5b91f5); .fs(20, 26px); .-b(#5b91f5, 2px); .ib;}
      > p {.fs(14, 20px); .c(rgba(255, 255, 255, .6)); .regular;}
    }
  }
  .event-copy {.fs(16); .c(white); .light; .mt(40); .mb(20); .font-g;}
  > span {.block; .wh(335, 100); .mh-c; .-box; .pt(19); .bgc(rgba(0, 0, 0, .3)); .mt(16); .fs(24); .font-g; .medium;
    &:before {.cnt; .block; .wh(16); .contain('@{academy}/vector/vector_star.svg'); .mh-c; .mb(8);}
    &:nth-of-type(1) {.mt(0);}
  }
  .banner {.wh(100%, 340); .cover('@{academy}/asset/bg-red-1.png'); .bg-c; .pt(60); .mt(60);
    h2 {.fs(24, 32px); .medium; .ls(-1);
      em {.light; .block;}
    }
    .btn-go {.wh(320, 60); .br(30); .br(50); .fs(24, 60px); .bgc(black); .mt(28); .c(white); .bold; .block; .mh-c; .font-g; .ls(-1);}
  }
  @media (@tl-up) {
    .p(80, 0, 0);
    h2 {.fs(60);}
    .sub-copy {.fs(24); .mt(22); .mb(32); .ls(-2);}
    > a {.wh(60); .m(0, 16);}
    > ul {
      > li { .wh(420, 320); .ib; .vat; .p(0, 12); .pt(42) !important; .mt(0);
        h3 {.fs(36); .ls(-2);}
        &:nth-of-type(1) {.mr(32);
          h3 {.p(43, 0, 64);}
        }
        &:nth-of-type(2) {
          h3 {.p(30, 0, 36);}
        }
        &:nth-of-type(3) {
          h3 {.p(34, 0, 36);}
        }
        &:nth-of-type(3) {.wh(872, 320); .mt(32);}
        h4 {.fs(28, 36px);}
        > p {.fs(18, 25); .regular;}
      }
    }
    .event-copy {.fs(24); .m(60, 0, 30);}
    > span {.ib; .wh(280, 140); .mt(0) !important; .ls(-1); .pt(40);
      &:nth-of-type(2) {.m(0, 16);}
    }
    .banner {.wh(100%, 300); .p(52, 0); .mt(80);
      h2 {.fs(36, 46px);}
      .btn-go {.wh(320, 72); .fs(28, 72px); .mt(24);}
    }
  }
  @media (@ds-up) {
    .p(124, 0, 0);
    h2 {.fs(80); }
    .sub-copy {.fs(32, 46); .mt(10); .mb(50);}
    > ul { .mt(40);
      > li {.wh(416, 320) !important; .mt(0) !important; .ml(16); .mr(0) !important;
        &:nth-of-type(1) {.ml(0);
          h3 {.p(52, 0, 56);}
        }
        &:nth-of-type(3) h3 {.p(52, 0, 56);}
      }
    }
    .event-copy {.fs(32); .m(100, 0, 43); .medium;}
    > span {.wh(416, 160); .pt(35); .fs(32);
      &:before {.mb(16); .wh(20);}
    }
    .banner {.mt(80); .h(560); .pt(136);
      h2 {.fs(52, 68px); .ls(-2);}
      .btn-go {.mt(50); .wh(480, 92); .fs(36, 92px);}
    }
  }

}
</style>
