<template>
  <PSection section-final id="academy-section-final">
    <div class="title-holder">
      <em class="status-tag">4 주차</em>
      <h2>1위 대학교가 아니라서 아쉽다구요?</h2>
      <p class="sub-copy">상위 포인트 8개 팀은 최종순위를 결정하는 <br class="hidden-ds-up" /><b>파이널 토너먼트</b>를 진행합니다.</p>
    </div>
    <div class="reward">
      <div class="box-holder">
        <h5>파이널 토너먼트 리워드</h5>
        <div class="box">
          <em class="status-tag">1위</em>
          <div class="prize">
            <img class="trophy" src="/img/event/academy/icon/icon-prize-3.svg" />
            <div class="first">
              <span>상금</span>
              <span>500 만원<em>+ 트로피</em></span>
            </div>
          </div>
        </div>
        <div class="box">
          <em class="status-tag">2위</em>
          <div class="prize">
            <img class="second-money" src="/img/event/academy/icon/icon-prize-2.svg" />
            <div class="second">
              <span>상금</span>
              <span>300 만원</span>
            </div>
          </div>
        </div>
        <div class="box">
          <em class="status-tag">3위</em>
          <div class="prize">
            <img class="money" src="/img/event/academy/icon/icon-prize-1.svg" />
            <div class="third">
              <span>상금</span>
              <span>200 만원</span>
            </div>
          </div>
        </div>
        <div class="box">
          <em class="status-tag">4위</em>
          <div class="prize">
            <img class="coin" src="/img/event/academy/icon/icon_coin.svg" />
            <div class="fourth">
              <span>상금</span>
              <span>100 만원</span>
            </div>
          </div>
        </div>
        <div class="box">
          <em class="status-tag">5-8위</em>
          <div class="prize">
            <img class="coin" src="/img/event/academy/icon/icon_coin.svg" />
            <div class="fifth">
              <span>상금</span>
              <span>50 만원</span>
            </div>
          </div>
        </div>
        <p><b>공통</b><em>|</em>1-8위팀 모두 소환사의 전당에 기록.</p>
      </div>
      <img class="char hidden-ds-up" src="/img/event/academy/asset/char-3.png" v-prx:progress="{r:[0,6], tx:[600,0]}" />
      <img class="char hidden-tw-down" src="/img/event/academy/asset/char-3.png" v-prx:intro="{r:[6,9], tx:[600,0]}" />
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'SectionFinal',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[section-final] { .pt(60);
  .status-tag {.h(40); .p(0, 18); .fs(16, 40px); .c(white); .bgc(#00af90); .bold;}
  .title-holder {.pl(20); .rel; z-index: 2;
    h2 { .fs(32, 40px); .c(black); .mt(12); .ls(-2);}
    .sub-copy {.fs(20, 28px); .c(#00af90); .mt(8); .ls(-1); .light; .font-g;
      b {.medium;}
    }
  }
  .reward { .p(30, 20, 32, 20); .bgc(#14d3a5); .mt(30);
    .box-holder {
      h5 {.fs(24); .c(black); .mb(58);}
      .status-tag {.bgc(black); .c(white); .fs(24); border-radius: 12px 12px 12px 0; .w(100); .-box; .tc; .abs; .lt(0, -25);}
      .box { .wh(335, 140); .rel; .mt(37); .bgc(white); .br(16); .mh-c; .pt(30);
        &:before {.cnt; .abs;}
        &:nth-of-type(1) {.h(277); .mt(0);
          .prize { .pl(0);}
        }
        .prize {
          > img {.w(94); .abs;}
          > div { .pl(120);
            span { .block; .tc; .font-g; .w(200);
              &:nth-of-type(1) {.fs(24); .c(#363840); .o(0.8); .mb(6);}
              &:nth-of-type(2) { .c(#df3a3a); .fs(40px); .extra-bold;
                em {.block; .fs(24); .c(black); .extra-bold; .mt(4);}
              }
            }
          }
        }
        &:nth-of-type(1) { .pt(50);
          .prize { .tc;
            img {.w(200); .mh-c; .static;}
            > div { .mh-c; .mt(16); .pl(0); .ib;}
          }
        }
        &:nth-of-type(2) {
          .prize {
            img {.lb(26, 38);}
          }
        }
        &:nth-of-type(3) {
          .prize {
            img {.lb(24, 48);}
          }
        }
        &:nth-of-type(4), &:nth-of-type(5) {
          .prize {
            img {.wh(70); .lb(41, 36);}
          }
        }
      }
      > p {.fs(16); .mh-c; .c(black); .mt(20);
        em {.ib; .m(0, 8);}
      }
    }
    .char {.hide;}
  }
  @media (@tl-up) {
    .pt(120);
    .status-tag {.wh(120, 48); .tc; .fs(20, 48px); .c(white); .bgc(#00af90); .bold;}
    .title-holder { .p(0, 60);
      h2 {.fs(72, 80px); .mt(24);}
      .sub-copy {.fs(28, 40px); .mt(24)}
    }
    .reward {.mt(60); .p(58, 58, 30); .rel; .crop;
      .box-holder { .rel; z-index: 1;
        h5 {.fs(32); .mb(48);}
        .status-tag {.w(140); .fs(32); }
        .box { .ib; .mt(0); .vab; .mr(20); .mb(60); .pt(0);
          .prize {.tc; .rel;
            img {.w(123); .static !important; .mh-c; .block;}
            > div {.pl(0) !important; width: 100% !important; .tc; justify-content: normal !important;
              span { .block; .w(100%); .tc;
                &:nth-of-type(1) {.fs(32); .c(#363840); .o(0.8); .mb(0);}
                &:nth-of-type(2) { .c(#df3a3a); .fs(40, 52px); .extra-bold;
                  em {.block; .fs(32); .c(black); .extra-bold;}
                }
              }
            }
          }
          &:nth-of-type(1) {.wh(330, 427); .pt(95);
            .prize {
              img {.w(250);}
              > div { .pt(0); .mt(30);
                span { width: auto;
                  &:nth-of-type(1) {.fs(36); .mb(8);}
                  &:nth-of-type(2) {.fs(60);
                    em {.mt(6);}
                  }
                }
              }
            }
          }
          &:nth-of-type(2) {.wh(252, 338); .pt(63);
            .prize {
              > div { .mt(30);
                span {
                  &:nth-of-type(1) {.fs(36); .mb(8);}
                  &:nth-of-type(2) {.fs(52);}
                }
              }
            }
          }
          &:nth-of-type(3) {.wh(212, 284); .pt(52);
            .prize {
              > div { .mt(24);}
            }
          }
          &:nth-of-type(4), &:nth-of-type(5) {.wh(212, 284); .pt(60);
            .prize {
              > div { .mt(19);}
            }
          }
        }
        > p {.abs; .lb(0, 15);}
      }
      .char {.block; .rb(0, -30); .abs; .h(1137); z-index: 0; .block;}
    }
  }
  @media (@ds-up) {
    .pt(160);
    .title-holder { .w(@screen-ds-min); .mh-c; .p(0); .w(@screen-ds-min); .mh-c;}
    .reward { .p(80, 0, 50); .mt(80); .-box; overflow: visible;
      .box-holder {.w(@screen-ds-min); .mh-c;
        .box { .ib; .mt(0); .vab; .mr(20); .mb(60);
          .prize {}
          &:nth-of-type(1) {.wh(304, 427);}
          &:nth-of-type(2) {.wh(252, 338);}
          &:nth-of-type(3) {.wh(208, 284);}
          &:nth-of-type(4) {.wh(208, 284);}
          &:nth-of-type(5) {.wh(208, 284); .mr(0);}
        }
      }
      .char {.b(0); .h(1000);}
    }
  }
}
</style>
