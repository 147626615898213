<template>
  <PSection academy-footer id="academy-footer" theme="white">
    <div class="text-holder">
      <h3>참여 시 꼭 확인하세요.</h3>
      <h4>대회 포인트 설명</h4>
      <p class="t">적용 대상: 대학, 팀, 개인 보상에 동일하게 적용됩니다.</p>
      <p class="pt">팀 / 개인 포인트 산정 방식 :경기 승패에 따라 포인트 획득<br />승리3점 | 패배1점</p>
      <p class="pt">대학 포인트 산정 방식 : 대학 소속 팀 포인트의 총 합계 <br />동일 대학 매칭 시 포인트: 승리 2점 | 패배 0점
      <p class="pt">연승 포인트: 3연승시 승리 포인트외 1점 추가됩니다. (개인포인트는 적용하지 않음)
      <p class="pt">이슈: 체크인 후 기권, 연락두절, 로스터 외 선수 참여시-2점입니다. <br class="hidden-ds-up" />(상대팀은 타 대학인 경우 3점, 같은 대학인 경우 2점)
      <h4>대회 일정 참고사항</h4>

      <p class="pt">대회 기간: 6/21(월) 부터 7/11(일)까지 3주간 매일 진행합니다.</p>
      <p class="pt">참가 신청 오픈 : 6/2(수) 부터 참가신청 접수합니다.</p>
      <p class="pt">평일(월~금) 경기 : 19시 | 21시 1일 2회 진행됩니다.</p>
      <p class="pt">주말(토~일) 경기 : 17시 | 19시 | 21시 1일 3회 진행됩니다.</p>
      <p class="pt">체크인: 대회 시작 1시간 전부터 50분간 진행됩니다. <br class="hidden-ds-up" />(ex: 7시 경기의 경우, 6시부터 6시 50분까지 체크인)</p>
      <p class="pt">대진표 공개 : 체크인 마감 후 10분 내 대진표 생성 후 공개합니다.</p>
      <p class="pt">로스터 변경 : 참가신청 마감시간까지 수정 가능합니다.</p>
      <h4>대회 방식 참고사항</h4>

      <p class="pt">총 경기 횟수 : 3주간 총48개 경기 진행 (평일 30개+ 주말 18개)</p>
      <p class="pt">일별 경기 횟수 : 평일 기준 1일 2회, 주말 기준 1일 3회 참여가 가능합니다.</p>
      <p class="pt">매칭 기준 : 1순위 - 비슷한 티어 | 2순위 - 다른 대학 | 3순위 - 대회 성적에 따라 매칭 상대 조정</p>

      <p class="pt">경기 로스터 : 최대 7명 (참가자 5명, 후보선수 2명)</p>
      <p class="pt">경기 방식 : 단판</p>
      <p class="pt">경기 진영 : 대진표상 랜덤으로 표시됩니다.</p>
      <p class="pt">경기 설정 : 소환사의 협곡5:5 | 토너먼트 드래프트</p>
      <p class="pt">경기 진행</p>
      <p class="num n1">대진표 확인 후 대진표 상 좌측에 위치한 팀이 커스텀 매치를 개설합니다.</p>
      <p class="num n2">커스텀 매치 개설 후 대진표 우측에 위치한 팀을 초대하여 규정에 따라 경기를 진행합니다.</p>

      <p class="num n3">로스터 외 선수 참여 시 자동 탈락됩니다.</p>
      <p class="pt">경기 종료 후 결과 입력</p>
      <p class="num n1">경기 후 승리팀은 결과 스크린샷을 사이트에 업로드 합니다.</p>
      <p class="num n2">패배팀은 상대 결과 스크린샷을 확인하고 결과를 승인합니다.</p>
      <p class="num n3">상대방이 승인하지 않은 결과는 10분 후 자동 승인됩니다. <br class="hidden-ds-up" />(운영진이 최종 확인할 예정입니다.)</p>
      <h4>대회 및 이벤트 보상</h4>
      <p class="pt">상금 제세공과금은 수상자가 부담합니다. </p>
      <p class="pt">우승깃발과 장학금은 1위 대학 장학처에 전달되며 전달 후 지급대상 결정에 있어 본 대회 주최 및 주관사는 관여하지 않습니다. </p>
      <p class="pt">SNS 이벤트의 참여 방법과 보상은 이벤트 시점에 변경될 수 있습니다.</p>
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'AcademiaFooter',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[academy-footer] { .bgc(#191919); .p(60, 10, 120);
  .text-holder {
    h3 {.c(rgba(255, 255, 255, .8)); .fs(20); .pb(20); .-b(rgba(255, 255, 255, .1), 2px); .tc; .mb(25);}
    h4 {.fs(16); .c(rgba(255, 255, 255, .6)); .mb(6); .mt(16); .font-spoqa;}
    > * {.p(0, 10);}
    p {.fs(11, 16px); .c(rgba(255, 255, 255, .4)); .rel;
      &.pt {.pl(24);
        &:before {.cnt; .wh(10); .bgc(rgba(255, 255, 255, .4)); .lt(10, 3); .abs;}
      }
      &.num {.pl(34);
        &:before {.ib; .abs; .lt(25, 0); }
        &.n1:before {content: '1.';}
        &.n2:before {content: '2.';}
        &.n3:before {content: '3.';}
      }
    }
  }
  &:after {.cnt; .-b(rgba(255, 255, 255, .1), 2px); .mt(20);}
  @media (@tl-up) {
    .p(80, 58, 200);
    .text-holder {
      h3 {.fs(32); .pb(40); .mb(40);}
      h4 {.fs(24); .mb(12); .mt(40);}
      > * {.p(0, 20);}
      p {.fs(16, 32px);
        &.pt { .pl(40);
          &:before {.wh(13); .lt(22, 8);}
        }
        &.num { .pl(60);
          &:before {.l(44);}
        }
      }
    }
    &:after {.mt(40);}
  }
  @media (@ds-up) {
    .p(120, 0, 200);
    .text-holder {.w(1080); .mh-c;
      h3 {.fs(32px);}
      > * {.p(0, 40);}
      p {
        &.pt {.pl(60);
          &:before {.l(42);}
        }
        &.num {.pl(80);
          &:before {.l(64);}
        }
      }
    }
    &:after {.w(1080); .mh-c;}
  }
}
</style>
