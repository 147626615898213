<template>
  <div event-academy>
    <EndIntro />
    <SectionFeature />
    <SectionBegin />
    <SectionFinal />
    <SectionEvent />
    <AcademiaFooter />
    <PSection class="quick-menu-holder">
      <QuickMenu
        v-prx:fixed
        v-if="quickMenu.menus.length"
        :button="quickMenu.button"
        :menus="quickMenu.menus"
        @button-click="discordConnect"
      />
    </PSection>
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import EndIntro from '@/views/components/landing/lol/EndIntro.vue';
import SectionFeature from '@/views/components/landing/lol/SectionFeature.vue';
import SectionBegin from '@/views/components/landing/lol/SectionBegin.vue';
import SectionFinal from '@/views/components/landing/lol/SectionFinal.vue';
import SectionEvent from '@/views/components/landing/lol/SectionEvent.vue';
import QuickMenu from '@/views/components/landing/QuickMenu.vue';
import AcademiaFooter from '@/views/components/landing/lol/Footer.vue';
import meta from '@/data/meta';

export default {
  name: 'Academy',
  mixins: [gtag],
  components: { AcademiaFooter, QuickMenu, SectionEvent, SectionFinal, SectionBegin, SectionFeature, EndIntro },
  data() {
    return {
      quickMenu: {
        button: {
          img: '/img/pages/icon/discord.svg',
          text: '대학 팀원 찾기',
        },
        menus: [
          {
            domId: 'academy-intro',
            name: '대회 소개',
          },
          {
            domId: 'academy-section-feature',
            name: '대회 방식',
          },
          {
            domId: 'academy-section-event',
            name: '이벤트',
          },
          {
            domId: 'academy-footer',
            name: '참고사항',
          },
        ],
      },
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    discordConnect() {
      window.open('https://discord.gg/PkpEJxM98K');
      this.trackEvent('landing_discord_btn', 'click', `${this.upRouteGameId}`);
      // intentionally track event twice
      this.trackEvent('landing_Aleague_discord_team', 'click', 'riot');
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
  created() {
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[event-academy] { .c(white); overflow: hidden; .rel;
  .quick-menu-holder { .fix; .z(10); .lt(0, 0);}
  > .quick {.hide;}
  @media (@tl-up) {
    > .quick {.fix; .lt(10, 150); .block; z-index: 10;}
  }
  @media (@ds-up) {
    > .quick {.l(20); }
  }
  @media (min-width: 1560px) {
    > .quick { .l(4vw);}
  }
}
</style>
