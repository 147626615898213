<template>
  <PSection academy-section-begin id="academy-section-begin">
    <div class="title-holder">
      <em class="status-tag">1-3 주차</em>
      <h2>3주간 매일 벌어지는 배틀</h2>
      <p class="sub-copy">참여할 때 마다 쌓이는 포인트!<br /><b>포인트가 가장 높은 대학교</b>가 우승 깃발의 주인입니다.</p>
    </div>
    <div class="time-step">
      <ul>
        <li>
          <em class="status-tag">평일</em>
          <h4>1일 2회</h4>
          <div class="items">
            <p class="time-box">19:00</p>
            <p class="time-box">21:00</p>
          </div>
        </li>
        <li>
          <em class="status-tag">주말</em>
          <h4>1일 3회</h4>
          <div class="items">
            <p class="time-box">17:00</p>
            <p class="time-box">19:00</p>
            <p class="time-box">21:00</p>
          </div>
        </li>
      </ul>
      <p><b>체크인</b><em>|</em>대회 시작 1시간 전부터 50분간 진행.</p>
      <img class="char" src="/img/event/academy/asset/char-2.png" v-prx:intro.aLeague.charA />
    </div>
    <div class="reward">
      <h4>대학랭킹</h4>
      <p class="sub-copy">대학랭킹은 대학 별 소속 팀들의<br class="hidden-tv-up" /> 포인트를 모두 합산한 점수로 결정되며 <br class="hidden-ds-up" />매 경기 후 업데이트 됩니다.</p>
      <ul>
        <li>
          <h3>1위 대학 리워드</h3>
          <p class="prize">장학금 <b>1000 만원</b></p>
          <div class="items">
            <p class="item">배틀 아카데미아<br />우승 깃발</p>
            <p class="item">소환사의 전당<br class="hidden-tv-up" />기록</p>
            <p class="item chicken">황금올리브 치킨<br />100마리 증정</p>
          </div>
        </li>
        <li>
          <h3>2-3위 대학 리워드</h3>
          <h4>대학 소속<br />참가자 100명에게<br />황금올리브 치킨 선물!</h4>
          <p class="item">소환사의 전당<br class="hidden-tv-up" />기록</p>
          <p class="item chicken">황금올리브 치킨<br />100마리 증정</p>
        </li>
      </ul>
      <p class="comment" style="line-height: 1.4em;">
        * 소환사의 전당은 라이엇 공식 아마추어 대회 수상자들의 기록을 남기는 명예의 공간입니다.<br> * 1위 대학 리워드 중 장학금과 깃발은 해당 대학 장학처에 전달됩니다.<br> * 치킨 쿠폰은 경기 참가횟수 기준으로 상위 100명에게 증정됩니다. (1인 1개 치킨 쿠폰 한정)
      </p>
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'SectionBegin',
  components: {},
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[academy-section-begin] { .pt(60);
  .status-tag {.h(40); .p(0, 18); .fs(16, 40px); .c(white); .bgc(#1e54b7); .bold; .tc;}
  .title-holder {.pl(20); .rel; z-index: 5;
    h2 { .fs(32, 40px); .c(black); .mt(12); .ls(-2);}
    .sub-copy {.fs(20, 28px); .c(#1e54b7); .mt(8); word-break: keep-all; .font-g; .light; .ls(-1);
      b {.medium;}
    }
  }
  .time-step { .bgc(#5d91f3); .p(30, 20, 40); .mt(30); .rel;
    .status-tag {.bgc(black); .ib; .vam;}
    > ul { .rel; z-index: 1;
      > li {
        h4 {.ib; .vam; .ml(24); .c(black); .medium;}
        &:nth-of-type(1) .items {.mb(14);}
        .items { .ib-list; .w(322); .tl; .mh-c; .mt(16);
          .time-box {.wh(151, 115); .br(20); .bgc(white); .c(#1e54b7); .mb(16); .fs(36, 115px); .tc; .extra-bold; .rel; .font-g;
            &:nth-of-type(odd) {.mr(20);}
          }
        }
      }
    }
    > p { .fs(14); z-index: 2; .rel; .mt(4);
      em {.ib; .m(0, 8);}
    }
    .char {.wh(288, 248); .abs; .rb(-20, 0); z-index: 0;}
  }
  .reward {.bgc(#e2ecff); .p(30, 20, 40);
    h4 {.fs(24); .c(black); .mb(6); .ls(-1);}
    .sub-copy {.fs(18, 26px); .c(#1e54b7); .font-g; .light; .ls(-1);}
    > ul { .mt(64);
      > li { .rel; .-a(#1e54b7, 2px); .br(28); .bgc(white); .w(335); .mh-c;
        h3 {.wh(280, 60); .bgc(#1e54b7); .br(12); .fs(24, 60px); .extra-bold; .tc; .abs; .lt(50%, -40); .t-xc; .ls(-1);}
        .chicken { .bgc(#24262c) !important; .c(white) !important; .mh-c; .pt(82) !important;
          &:before {.cnt; .abs; .wh(108, 96); .contain('@{attach}/bbq.svg'); .lt(50%, 9); .t-xc;}
        }
        .item { .wh(120); .br(6); .bgc(#ebebf0); .fs(12, 14px); .c(#24262c); .bold; .rel; .tc; .pt(77); }
        &:nth-of-type(1) { .pt(47); .pb(25); .mb(65);
          .prize { .fs(20); .wh(280, 58px); .c(#363840); .bgc(#ebebf0); .tc; .mh-c; .font-g;
            b {.fs(36, 60px); .c(#df3a3a); .extra-bold; .h(100%); .ib; .ml(4);}
            &:after {.cnt; .wh(20, 34); .contain('@{academy}/vector/icon_plus.svg'); .mt(15); .mh-c; .bg-c;}
          }
          .items { .ib-list; .tc; .mt(64);
            > p {.mb(12);
              &:nth-of-type(odd) {.mr(12);}
              &:nth-of-type(3) {.wh(252, 120); .mr(0);
                &:before {.l(50%); .t-xc;}
              }
              &:nth-of-type(1):before {.cnt; .abs; .wh(60); .contain('@{academy}/icon/icon_flag.svg'); .lt(35, 12); }
              &:nth-of-type(2):before {.cnt; .abs; .wh(55); .contain('@{academy}/icon/icon-reward-1.svg'); .lt(40, 16); }
            }
          }
        }
        &:nth-of-type(2) { .p(50, 10, 36); .tc;
          h4 {.fs(28, 34px); .c(black); .tc; .mb(36); .ls(-2); .medium;}
          .item {.ib; .vat;
            &:nth-of-type(1) {.mr(12); .rel;
              &:before {.cnt; .abs; .wh(55); .contain('@{academy}/icon/icon-reward-1.svg'); .lt(40, 16); }
            }
          }
        }
      }
    }
    .comment { .c(#1e54b7); .fs(14, 17px); .tl; .c(#1e54b7); .mt(30);
      em {.ib; .m(0, 5);}
      span {.block; .mt(4);}
      &.c2 {.mt(18);}
    }
  }
  @media (@tl-up) {
    .pt(0);
    .status-tag {.wh(120, 48); .fs(20, 48px);}
    .title-holder { .p(120, 58, 60);
      h2 {.fs(72, 80px); .mt(24); .w(480);}
      .sub-copy {.fs(28, 36px); .w(450); .rel; z-index: 2; .ls(-2); .mt(24);}
    }
    .time-step {.m(0); .p(40, 0);
      .status-tag {.w(72); .fs(18);}
      > ul {.w(910); .mh-c; .ib-list;
        > li {
          &:nth-of-type(1) {.mr(92);}
          h4 {.fs(20);}
          .items { .mt(19); width: auto;
            .time-box {.fs(40, 115px); .mr(0) !important; .ml(20);
              &:nth-of-type(1) {.ml(0);}
            }
          }
        }
      }
      > p {.w(910); .mh-c; .fs(20);}
      .char {.wh(890, 764); .r(-100);}
    }
    .reward { .p(80, 58, 60);
      h4 {.fs(36); .mb(10); .ls(-2);}
      .sub-copy {.fs(24, 30px); .ls(-2);}
      > ul { .mt(90);
        > li { .w(908); .br(48);
          h3 {.wh(380, 72); .fs(40, 72px);}
          .item {.wh(180); .br(8); .pt(117); .fs(18, 22px);}
          .chicken { .pt(119) !important;
            &:before {.wh(148, 94); .t(18);}
          }
          &:nth-of-type(1) { .p(66, 34, 52); .mb(66);
            .prize {.wh(100%, 120); .fs(44); .-box; .pt(34);
              b {.fs(80); .ml(24);}
              &:after {.mt(24); .wh(29, 48);}
            }
            .items {.mt(100);
              > p {.mr(0) !important; .mb(0); .ml(20);
                &:nth-of-type(1) {.ml(0);
                  &:before {.wh(80); .lt(58, 25);}
                }
                &:nth-of-type(2) { .pt(128);
                  &:before {.wh(74); .lt(63, 30);}
                }
                &:nth-of-type(3) {.wh(180);}
              }
            }
          }
          &:nth-of-type(2) { .p(100, 0, 0, 0); .tc; .-box; .h(343);
            h4 {.ib; .vam; .w(360); .fs(36, 48px); .mb(0); .mr(20);}
            .chicken {.ib;.ml(16);.vam;}
            .item {.ib; .vam;
              &:nth-of-type(1) { .pt(128); .mr(0);
                &:before {.wh(74); .lt(63, 30);}
              }
            }
          }
        }
      }
      .comment {.fs(20, 24px); .mt(30);}
    }
  }
  @media (@ds-up) {
    .title-holder { .w(@screen-ds-min); .mh-c; .p(160, 0, 80);
      h2 {width: auto;}
      .sub-copy {width: auto;}
    }
    .time-step { .p(56, 0, 60);
      > ul {.w(@screen-ds-min); .mh-c;
        > li {
          &:nth-of-type(1) {.mr(110);}
        }
      }
      > p {.w(@screen-ds-min); .m(0); .mh-c;}
      .char {.wh(937, 805); .r(-30);}
    }
    .reward { .p(80, 0, 60);
      h4 {.w(@screen-ds-min); .mh-c;}
      .sub-copy {.fs(28, 39px); .w(@screen-ds-min); .mh-c;}
      > ul {.w(@screen-ds-min); .mh-c; .ib-list;
        > li { .wh(828, 520); .-box; .mb(0) !important;
          &:nth-of-type(1) {
            .prize { .wh(664, 124);}
          }
          &:nth-of-type(2) {.wh (416, 520); .mt(0); .ml(16); .p(80, 0, 62);
            h4 {.mb(57); .mr(0);}
            .chicken {.ml(12);}
          }
        }
      }
      .comment {.fs(20, 24px); .mt(30); .w(@screen-ds-min); .mh-c;}
    }
  }
}
</style>
