<template>
  <PSection academy-intro id="academy-intro" theme="white">
    <div class="contents-holder">
      <img src="/img/asset/logo/BattleAcademia.svg" />
      <h2>진짜 마지막 배틀이 시작된다!
        <span>파이널 토너먼트, <br class="hidden-tv-up" />상금 500만원과 트로피를 거머쥘 <br class="hidden-tv-up" />최고의 팀은?</span>
      </h2>
      <ul class="dates">
        <li>
          <div>
            <span class="tl">파이널 토너먼트</span>
            <span class="tr">7월 17일 (토)</span>
          </div>
        </li>
      </ul>
      <div class="mov">
        <div class="holder">
          <img src="/img/event/academy/asset/trophy_n-1.png" alt="trophy" />
        </div>
      </div>
      <router-link @click.native="onClickNow" class="btn-go" to="/b/lol_academia">지금 확인하기</router-link>
    </div>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';

export default {
  name: 'EndIntro',
  mixins: [gtag],
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    onClickNow() {
      this.trackEvent(`landing_challenge_01`, 'click', `${this.upRouteGameId}`);
      // intentionally track twice
      this.trackEvent('landing_Aleague_go1', 'click', 'riot');
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[academy-intro] { .rel; .-box; .cover('@{academy}/asset/portal_BG_001.jpg'); .bg-c; .bg-s(100%, 100%); .tc; .pb(40); .pt(160);
  .contents-holder {
    > img {.w(160); .mb(16);}
    h2 {.fs(30); .extra-bold; .mb(8); .ls(-2);
      > span {.block; .fs(16, 20px); .c(#5b91f5); .regular; word-break: keep-all; .ls(-1); .mt(10);}
    }
    .dates { .mt(40);
      > li { .wh(308, 32); .mh-c; .rel; .br(6); .mb(12); .fs(14, 32px); .tl; .contain('@{academy}/asset/bar-3.png');
        > div {.rel; z-index: 1; .crop;}

        &:nth-of-type(1) {.contain('@{academy}/asset/bar-3.png'); .bg-w(52%); .bgc(#5b91f5);
          span { .block; .w(50%); .fl; .tc; }
          .tl { .medium; }
          .tr {.c(white); }
        }
      }
    }
    .info {.c(rgba(255, 255, 255.4)); .fs(12);}
    .mov { .mt(-10); .ml(-12vw); .mr(0); .tc;
      img { .max-w(1200); .w(112%); }
    }
    .btn-go {.max-w(320); .wh(90%, 60); .br(50); .fs(21, 60px); .bgc(#df3a3a); .mh-c; .c(white); .extra-bold; .mt(42); .font-g; .ls(-1); .block;}
  }
  @media (@tl-up) {
    .pb(58); .pt(180);
    .contents-holder {
      > img {.w(240); .mb(34);}
      h2 {.mt(0); .fs(60);
        > span {.fs(24, 31px); .ls(-2);}
      }
      .dates { .ib-list; .w(100%); .tc; .mt(40);
        > li { .mb(0); .wh(308, 35); .br(8); .lh(36);
          > div {
            > p {.block; .pt(12); .fs(12, 12px); .c(white); .o(.4); .tc;}
          }
          &:nth-of-type(1) {
          }
        }
      }
      .mov { .mt(-20); .ml(0); .mr(0);
        img { .w(100%); }
      }
      .btn-go {.max-w(320); .h(72); .fs(28, 72px); .ls(-2);}
    }
  }
  @media (@ds-up) {
    .pb(116);
    .contents-holder {
      > img {.w(360); .mb(40);}
      h2 { .fs(80);
        > span {.fs(32);}
      }
      .dates {.mt(60);
        > li { .wh(420, 48); .fs(20, 50px);
          > div {
            > p {.pt(16); .fs(16);}
          }
          &:nth-of-type(1) {
          }
        }
      }
      .mov { .mt(-20); .ml(0); .mr(0); }
      .btn-go {.max-w(480); .h(92); .fs(36, 92px); .mt(-13);}
    }
  }
}
</style>
