<template>
  <PSection academy-section-feature id="academy-section-feature">
    <div class="contents-holder">
      <h2>여러분은 대회만 즐기세요!</h2>
      <ul>
        <li>
          <ImageAsset url="/img/event/academy/asset/box-2.png" />
          <div class="sub-title">
            <em class="status-tag">리그 특전</em>
            <h4>대회 참가만 해도 <br /><b>수수께끼 스킨</b> 증정!</h4>
          </div>
        </li>
        <li>
          <ImageAsset url="/img/event/academy/asset/box-1.png" />
          <div class="sub-title">
            <em class="status-tag">대회 포인트 달성 시</em>
            <h4>누구든지 15 point 달성 시, <br /><b>프리미엄 수수께끼 스킨</b> 증정!</h4>
          </div>
        </li>
      </ul>
    </div>
  </PSection>
</template>

<script>
import ImageAsset from '@/views/components/common/ImageAsset.vue';

export default {
  name: 'SectionFeature',
  components: { ImageAsset },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[academy-section-feature] { .cover('@{academy}/asset/bg-red-2.png'); .bg-yc; .bg-x(0); .p(57, 20, 52);
  h2 {.fs(30); .tc; .mb(40); .ls(-2);}
  .contents-holder {
    > ul {
      > li {.rel; .min-h(100);
        &:nth-of-type(1) {.mb(20);}
        [image-asset] {.wh(100); .br(20); .ib; .vat; .abs; .lt(0, 0);}
        .sub-title {.ib; .vat; .pl(120); .w(100%); .-box; .pt(4);
          .status-tag {.bgc(black); .fs(14, 32px); .p(0, 10); .tc; .mb(8); .mt(12);}
          h4 {.fs(16, 21px); .regular; word-break: keep-all; .ls(-1);
            b {.extra-bold;}
          }
        }
      }
    }
  }
  @media (@tl-up) {
    .p(80, 0);
    .contents-holder {.w(760); .mh-c;
      h2 {.fs(48); .mb(43);}
      > ul {
        > li { .min-h(200);
          &:nth-of-type(1) {.mb(40);}
          [image-asset] {.wh(200);}
          .sub-title { .pl(240); .pt(0); .mt(20);
            .status-tag {.p(0, 20); .fs(20, 48px); .mb(20);}
            h4 {.fs(36, 48px); .ls(-2);
            }
          }
        }
      }
    }
  }
  @media (@ds-up) {
    .p(100, 0, 122);
    .contents-holder { .w(100%);
      h2 {.fs(60); .mb(60);}
      > ul { .ib-list; .tc; white-space: nowrap;
        > li { width: auto; .tl; .mb(0) !important; .min-h(160);
          [image-asset] {.wh(160);}
          &:nth-of-type(1) {.mr(160);}
          .sub-title {.pl(200); .mt(0);
            .status-tag {.mt(0); .mb(23);}
            h4 {.fs(36, 48px); width: auto;}
          }
        }
      }
    }
  }
}
</style>
